import React, { useState } from "react";
import { NextBtn, PrevBtn } from "../assets";
import { Breadcrumb, Sidebar } from "../components";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { langState } from "../recoil/atoms/langState";
import CourseList, { CourseType } from "../assets/Course/CourseList";
import { useTranslation } from "react-i18next";

const View = () => {
  const [isSideOpen, setIsSideOpen] = useState(true);
  const { id, sub, video } = useParams<{
    id: string;
    sub: string;
    video: string;
  }>();
  const nav = useNavigate();
  const [lang, setLang] = useRecoilState<"ko" | "en">(langState);
  const { t } = useTranslation();

  if (!id || !sub || !video) {
    return <div>해당 강의나 과목을 찾을 수 없습니다.</div>;
  }

  const selectedCourse: CourseType | undefined = CourseList.find(
    (c) => c.id === parseInt(id)
  );
  const selectedSubject = selectedCourse?.subject.find(
    (s) => s.subjectId === parseInt(sub)
  );
  const selectedVideo = selectedSubject?.courses[parseInt(video) - 1];

  const isFirstVideo = parseInt(video) === 1;
  const isLastVideo = selectedSubject?.courses.length === parseInt(video);

  if (!selectedCourse || !selectedSubject || !selectedVideo) {
    return <div>해당 강의나 과목을 찾을 수 없습니다.</div>;
  }

  return (
    <div className="View">
      <Sidebar
        isSideOpen={isSideOpen}
        setIsSideOpen={setIsSideOpen}
        subject={selectedSubject}
      />
      <div className={`InnerContainer ${isSideOpen ? "" : "collapsed"}`}>
        <Breadcrumb />
        <div className="TopContainer">
          <div className="Title">
            <span className="MainTitle">{selectedCourse.title[lang]}</span>
            <span className="Desc">{selectedVideo.courseTitle[lang]}</span>
          </div>
          <div className="Buttons">
            <div
              onClick={() => {
                if (!isFirstVideo) {
                  nav(`/view/${id}/${sub}/${parseInt(video) - 1}`);
                }
              }}
            >
              <img src={PrevBtn} alt="Previous" />
              {t(`View.Prev`)}
            </div>
            <span>|</span>
            <div
              onClick={() => {
                if (!isLastVideo) {
                  nav(`/view/${id}/${sub}/${parseInt(video) + 1}`);
                }
              }}
            >
              {t(`View.Next`)}
              <img src={NextBtn} alt="Next" />
            </div>
          </div>
        </div>
        <div className="Video">
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/${
              selectedVideo.link.split("=")[1]
            }`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>
        <div className="TopContainer mobile">
          <div className="Title">
            <span className="MainTitle">{selectedCourse.title[lang]}</span>
            <span className="Desc">
              {t(`View.Lecture`, { count: parseInt(video) })} -{" "}
              {selectedVideo.courseTitle[lang]}
            </span>
          </div>
          <div className="Buttons">
            <div
              onClick={() => {
                if (!isFirstVideo) {
                  nav(`/view/${id}/${sub}/${parseInt(video) - 1}`);
                }
              }}
            >
              <img src={PrevBtn} alt="Previous" />
              {t(`View.Prev`)}
            </div>
            <span>|</span>
            <div
              onClick={() => {
                if (!isLastVideo) {
                  nav(`/view/${id}/${sub}/${parseInt(video) + 1}`);
                }
              }}
            >
              {t(`View.Next`)}
              <img src={NextBtn} alt="Next" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default View;
