import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import {
  LogoSection,
  DescriptionSection,
  CourseSection,
  LicenseSection,
  ScrollButton,
  GoToApplicate,
} from "../components/index";

const Main = () => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 1040);
  const lastPage = 3;
  const timeoutRef = useRef<number | null>(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1040);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      return;
    }

    const handleScroll = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = window.setTimeout(() => {
        let nextPage = Math.floor(window.scrollY / window.innerHeight);
        if (window.innerWidth < 1710) {
          nextPage = Math.floor(window.scrollY / window.innerHeight + 0.5);
        }
        setCurrentPage(nextPage);
      }, 10);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isMobile]);

  const handleWheel = (e: WheelEvent) => {
    if (isMobile) {
      return;
    }

    const isScrollingDown = e.deltaY > 0;
    if (isScrollingDown && currentPage < lastPage) {
      scrollToNextPage();
    } else if (!isScrollingDown && currentPage > 0) {
      scrollToPrevPage();
    }
  };

  const scrollToNextPage = () => {
    const nextPage = currentPage + 1;
    const offset = nextPage * window.innerHeight + 1;
    scroll.scrollTo(offset, {
      duration: 500,
      smooth: "easeInOutQuart",
    });
  };

  const scrollToPrevPage = () => {
    const prevPage = currentPage - 1;
    const offset = Math.max(0, prevPage * window.innerHeight);
    scroll.scrollTo(offset, {
      duration: 500,
      smooth: "easeInOutQuart",
    });
  };

  const location = useLocation();

  const scrollToElement = (elementId: string) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const elementId = location.hash.substring(1);
    if (elementId) {
      scrollToElement(elementId);
    }
  }, [location]);

  useEffect(() => {
    if (isMobile) {
      return;
    }

    window.addEventListener("wheel", handleWheel);
    return () => {
      window.removeEventListener("wheel", handleWheel);
    };
  }, [currentPage, isMobile]);

  const handleButtonClick = (page: number) => {
    const offset = page * window.innerHeight;
    scroll.scrollTo(offset, {
      duration: 500,
      smooth: "easeInOutQuart",
    });
    setCurrentPage(page);
  };

  return (
    <div className="Main">
      <section id="page1">
        <LogoSection />
      </section>

      <section id="page2">
        <DescriptionSection />
      </section>

      <section id="page3">
        <CourseSection />
      </section>

      <section id="page4">
        <LicenseSection />
      </section>

      <GoToApplicate />
      <ScrollButton
        currentPage={currentPage}
        handleButtonClick={handleButtonClick}
      />
    </div>
  );
};

export default Main;
