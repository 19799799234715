import React from "react";
import { Breadcrumb } from "../components";
import CourseList, { CourseType } from "../assets/Course/CourseList";
import { useNavigate, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { langState } from "../recoil/atoms/langState";
import { useTranslation } from "react-i18next";

const CourseDetail = () => {
  const nav = useNavigate();
  const loc = useLocation();
  const path = parseInt(loc.pathname.split("/")[2], 10) - 1;
  const data = CourseList[path];
  const [lang, setLang] = useRecoilState<"ko" | "en">(langState);
  const { t } = useTranslation();

  if (!data) {
    return <div>해당 강의를 찾을 수 없습니다.</div>;
  }

  return (
    <div className="CourseDetail">
      <div className="InnerContainer">
        <Breadcrumb />
        <div className="wrap">
          <div className="LeftSide">
            <div className="LeftInner">
              <div className="Numbering">
                <span>{data.id < 10 ? "0" + data.id : data.id}</span>
              </div>

              <div className="Desc">
                <span className="Title">{data.title[lang]}</span>
                <span className="SubDesc">
                  {t(`CourseDetail.TotalLecture`, {
                    count: data.subject.reduce(
                      (total, subj) => total + subj.courses.length,
                      0
                    ),
                  })}
                </span>
              </div>
            </div>
          </div>
          <div className="RightSide">
            {data.subject.map((course) => (
              <div key={course.subjectId}>
                <div className="SubjTitle">
                  {t(`CourseDetail.nSub`, { count: course.subjectId })}
                  {course.subjectTitle[lang]}
                </div>
                {course.courses.map((singleCourse, idx) => (
                  <React.Fragment key={idx}>
                    <div className="SubjCourse">
                      <div className="NumberingDiv">
                        <span className="Numbering">
                          {t(`CourseDetail.Sub`, { count: idx + 1 })}
                        </span>
                        {singleCourse.courseTitle[lang]}
                      </div>
                      <div className="SubjCourseDetail">
                        <span>{singleCourse.duration}</span>
                        <div
                          className="btn"
                          onClick={() => {
                            nav(
                              `/view/${data.id}/${course.subjectId}/${idx + 1}`
                            );
                          }}
                        >
                          {t(`CourseDetail.Btn`)}
                        </div>
                      </div>
                    </div>
                    {(idx + 1) % 3 === 0 && <div className="RowLine"></div>}
                  </React.Fragment>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseDetail;
