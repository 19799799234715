import React from "react";
import { ShrinkBtn, TakingCourseIcon, ExpandBtn } from "../../assets";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { langState } from "../../recoil/atoms/langState";
import i18next, { t } from "i18next";

const Sidebar = ({ isSideOpen, setIsSideOpen, subject }: any) => {
  const { id, sub, video }: any = useParams();
  const watching = parseInt(video, 10) - 1;
  const [lang, setLang]: any = useRecoilState(langState);
  const nav = useNavigate();

  return (
    <div className={`Sidebar ${isSideOpen ? "open" : "collapsed"}`}>
      <div className="BtnDiv">
        <img
          src={isSideOpen ? ShrinkBtn : ExpandBtn}
          onClick={() => {
            setIsSideOpen(!isSideOpen);
          }}
        />
      </div>
      {isSideOpen && subject && (
        <div className="Inner">
          <div className="Title">{subject.subjectTitle[lang]}</div>
          {subject.courses.map((course: any, index: any) => (
            <div
              className={`Courses ${index === watching ? "now" : ""}`}
              key={index}
              onClick={() => nav(`/view/${id}/${sub}/${index + 1}`)}
            >
              <div className="CourseTitle">
                {t(`View.Sidebar.Lecture`, { count: index + 1 })}
                {course.courseTitle[lang]}
              </div>
              <div className="CourseDesc">
                <span className="Time">{course.duration}</span>
                {index === watching && (
                  <div className="Watching">
                    <img src={TakingCourseIcon} alt="Watching Icon" />
                    <span>{t(`View.Sidebar.Watching`)}</span>
                  </div>
                )}
              </div>
            </div>
          ))}

          <div className="Btn">
            <div
              className="GoToList"
              onClick={() => {
                nav(`/course/${id}`);
              }}
            >
              {t(`View.Sidebar.Btn`)}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
