import { useState, useEffect, useRef } from "react";
import { AcumenLabHR, AcumenMiddleLogo } from "../../assets";
import i18n from "i18next";
import { useRecoilState } from "recoil";
import { langState } from "../../recoil/atoms/langState";
import { useTranslation } from "react-i18next";

const LogoSection = () => {
  const { t } = useTranslation();
  const [lang, setLang]: any = useRecoilState(langState);
  const [activeBtn, setActiveBtn] = useState(3);
  const mobileDescRef = useRef<any>(null);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  const text = [
    {
      title: t(`main.LogoSection.Title1`),
      description: t(`main.LogoSection.Box1`),
    },
    {
      title: t(`main.LogoSection.Title2`),

      description: t(`main.LogoSection.Box2`),
    },
    {
      title: t(`main.LogoSection.Title3`),

      description: t(`main.LogoSection.Box3`),
    },
  ];

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        mobileDescRef.current &&
        !mobileDescRef.current.contains(event.target)
      ) {
        setActiveBtn(3);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [mobileDescRef]);

  return (
    <div className="LogoSection">
      <div className="LogoContainer">
        <img className="Logo" src={AcumenMiddleLogo} alt="Acumen Middle Logo" />
        <img className="AcumenLab" src={AcumenLabHR} alt="Acumen Lab" />
      </div>
      <div
        className="GoToLicense"
        onClick={() => {
          {
            lang === "ko"
              ? window.open("https://forms.gle/xzvc1ZhNjJB1kfLUA")
              : window.open("https://forms.gle/9Svf9KHzEkFrw5jw7");
          }
        }}
      >
        {t(`main.LogoSection.Button`)}
      </div>
      <div className="LicenseDescription">
        <div
          className={`MobileDescriptionBox ${activeBtn < 3 ? "" : "hidden"}`}
          ref={mobileDescRef}
        >
          {activeBtn < 3 && text[activeBtn].description}
        </div>

        <div className="HoverDiv">
          {text.map((item, index) => (
            <div
              className={`SlideBtn ${activeBtn === index ? "active" : ""}`}
              key={index}
              onClick={() => {
                setActiveBtn(index);
              }}
            >
              <div className="DescriptionWrapper">
                <div className="DescriptionBox">{item.title}</div>
                <div className="HiddenBox">{item.description}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LogoSection;
