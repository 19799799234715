export interface LicenseType {
  id: number;
  title: {
    ko: string;
    en: string;
  };
  frontContent: string;
  backContent: {
    ko: string;
    en: string;
  };
  class: string;
}

const LicenseList: LicenseType[] = [
  {
    id: 1,
    title: {
      ko: "암호 정보 전문가 자격증",
      en: "Cryptography Information Expert Certification",
    },
    frontContent: "CIEC:\nCryptography Information\nExpert Certification",
    backContent: {
      ko: `암호 정보 전문가 자격증(CIEC)은 정보 보안의 필수적인 요소인 암호화 기술과 이론에 대한 포괄적인 이해를 제공합니다.\n이 자격증은 암호 알고리즘, 암호화 프로토콜, 키 관리, 그리고 최신 암호화 기술 동향에 대한 깊이 있는 지식을 다룹니다.\nCIEC를 통해 정보 보안 전문가로서의 기초를 다질 수 있습니다.`,
      en: `The Cryptography Information Expert Certification (CIEC) provides a comprehensive understanding of cryptographic techniques and theories, which are essential elements of information security. This certification covers in-depth knowledge of encryption algorithms, cryptographic protocols, key management, and the latest trends in cryptographic technologies. You can establish a foundation as an information security expert through CIEC.`,
    },
    class: "일반 암호 시스템",
  },
  {
    id: 2,
    title: {
      ko: "암호 기술 전문가 자격증",
      en: "Cryptography Technology Expert Certification",
    },
    frontContent: "CTEC:\nCryptography Technology\nExpert Certification",
    backContent: {
      ko: `암호 기술 전문가 자격증(CTEC)은 암호화 기술의 설계, 구현 및 관리에 중점을 둡니다.\n이 자격증은 다양한 암호화 알고리즘, 디지털 서명, 공개 키 인프라(PKI) 및 안전한 통신 프로토콜을 포함한 실무 기술을 습득하도록 설계되었습니다.\nCTEC는 실무 중심의 암호화 기술 전문가로 성장하는 데 필수적입니다.`,
      en: `The Cryptographic Technology Expert Certification (CTEC) focuses on the design, implementation, and management of cryptographic technology. This certification is designed to acquire practical skills, including various encryption algorithms, digital signatures, Public Key Infrastructure (PKI), and secure communication protocols. CTEC is essential for growing into a hands-on cryptographic technology professional.`,
    },
    class: "일반 암호 시스템",
  },
  {
    id: 3,
    title: {
      ko: "암호 분석 전문가 자격증",
      en: "Cryptography Analysis Certification",
    },
    frontContent: "CAC:\nCryptography Analysis\nCertification",
    backContent: {
      ko: `암호 분석 전문가 자격증(CAC)은 암호화 시스템의 취약성을 식별하고 분석하는 데 필요한 기술을 제공합니다.\n이 자격증은 암호 해독, 암호 공격 기법, 암호 프로토콜의 취약성 분석 등을 다루며, 암호 시스템의 안전성을 평가하고 개선하는 능력을 갖추게 합니다.`,
      en: `The Cryptography Analysis Certification (CAC) provides the necessary skills to identify and analyze vulnerabilities in cryptographic systems. This certification covers code decryption, cryptographic attack techniques, vulnerability analysis of cryptographic protocols, and equips individuals with the ability to assess and improve the security of cryptographic systems.`,
    },
    class: "일반 암호 시스템",
  },
  {
    id: 4,
    title: {
      ko: "암호 시스템 전문가 자격증",
      en: "Cryptographic Systems Expert Certification",
    },
    frontContent: "CSEC:\nCryptographic Systems\nExpert Certification",
    backContent: {
      ko: `암호 시스템 전문가 자격증(CSEC)은 복잡한 암호화 시스템의 설계, 구현 및 유지보수를 전문으로 합니다.\n이 자격증은 대규모 네트워크 보안, 암호화 데이터 저장소, 안전한 통신 채널 구축 등 시스템 수준에서의 암호화 적용 방법을 포함합니다.\nCSEC는 통합적인 암호화 시스템 전문가로서의 역량을 강화합니다.`,
      en: `The Cryptographic Systems Expert Certification (CSEC) specializes in the design, implementation, and maintenance of complex cryptographic systems. This certification covers methods for applying encryption at the system level, including large-scale network security, encrypted data storage, and establishing secure communication channels. CSEC strengthens your capabilities as an integrated cryptographic systems expert.`,
    },
    class: "일반 암호 시스템",
  },
  {
    id: 5,
    title: {
      ko: "양자 암호 전문가 자격증",
      en: "Quantum Cryptography Expert Certification",
    },
    frontContent: "QCEC:\nQuantum Cryptography\nExpert Certification",
    backContent: {
      ko: `양자 암호 전문가 자격증(QCEC)은 양자 암호화 기술의 기초와 응용에 대한 심층적인 지식을 제공합니다.\n이 자격증은 양자 키 분배(QKD), 양자 내성 암호화 알고리즘, 그리고 양자 암호화 시스템의 설계 및 구현을 다룹니다.\nQCEC는 양자 암호화 분야에서 선도적인 전문가로 성장할 수 있는 기반을 제공합니다.`,
      en: `The Quantum Cryptography Expert Certification (QCEC) provides in-depth knowledge of the fundamentals and applications of quantum cryptographic technology. This certification covers quantum key distribution (QKD), post-quantum cryptographic algorithms, and the design and implementation of quantum cryptographic systems. The QCEC lays the foundation for becoming a leading expert in the field of quantum cryptography.`,
    },
    class: "양자 내성 암호 PQC",
  },
  {
    id: 6,
    title: {
      ko: "양자 암호 분석 자격증",
      en: "Quantum Cryptography Analyst Certification",
    },
    frontContent: "QCA:\nQuantum Cryptography\nAnalyst Certification",
    backContent: {
      ko: `양자 암호 분석 자격증(QCA)은 양자 암호화 시스템의 분석 및 평가에 중점을 둡니다.\n이 자격증은 양자 컴퓨팅의 원리, 양자 알고리즘 분석, 양자 시스템의 취약성 식별 및 개선을 포함한 다양한 주제를 다룹니다.\nQCA는 양자 암호화의 보안성을 평가하는 데 필요한 전문 지식을 제공합니다.`,
      en: `The Quantum Cryptography Analyst Certification (QCA) focuses on the analysis and evaluation of quantum cryptography systems. This certification covers a variety of topics, including the principles of quantum computing, analysis of quantum algorithms, and identifying and remediating vulnerabilities in quantum systems. QCA provides the expertise needed to evaluate the security of quantum cryptography.`,
    },
    class: "양자 내성 암호 PQC",
  },
  {
    id: 7,
    title: {
      ko: "양자 정보 보호 자격증",
      en: "Quantum Information Protection Certification",
    },
    frontContent: "QIPC:\nQuantum Information\nProtection Certification",
    backContent: {
      ko: `양자 정보 보호 자격증(QIPC)은 양자 기술을 활용한 정보 보호 방법론에 대해 포괄적으로 다룹니다.\n이 자격증은 양자 암호화, 양자 난수 생성, 양자 채널 보안 및 양자 정보 이론에 기반한 데이터 보호 기술을 습득할 수 있게 합니다.\nQIPC는 양자 정보 보호의 최전선에서 활동하는 전문가로서의 역량을 강화합니다.`,
      en: `The Quantum Information Protection Certification (QIPC) comprehensively covers methodologies for information protection utilizing quantum technologies. This certification enables the acquisition of data protection techniques based on quantum encryption, quantum random number generation, quantum channel security, and quantum information theory. QIPC enhances the capabilities of professionals working at the forefront of quantum information protection.`,
    },
    class: "양자 내성 암호 PQC",
  },
  {
    id: 8,
    title: {
      ko: "양자 암호 시스템 전문가 자격증",
      en: "Quantum Cryptography Technology Expert Certification",
    },
    frontContent:
      "QCTEC:\nQuantum Cryptography\nTechnology Expert Certification",
    backContent: {
      ko: `양자 암호 시스템 전문가 자격증(QCTEC)은 양자 암호화 시스템의 기술적 측면에 중점을 둡니다.\n이 자격증은 양자 암호화 장비의 설계, 양자 네트워크 구성, 양자 암호화 프로토콜 구현 등을 포함한 다양한 기술을 다룹니다.\nQCTEC는 실무적인 양자 암호화 기술 전문가로서 성장하는 데 필요한 지식을 제공합니다.`,
      en: `The Quantum Cryptography Technology Expert Certification (QCTEC) focuses on the technical aspects of quantum cryptography systems. This certification covers a wide range of skills, including the design of quantum cryptographic devices, the configuration of quantum networks, and the implementation of quantum cryptography protocols. QCTEC provides the knowledge necessary to grow as a practical expert in quantum cryptography technology.`,
    },
    class: "양자 내성 암호 PQC",
  },
  {
    id: 9,
    title: {
      ko: "양자 통신 보안 전문가 자격증",
      en: "Quantum Communication Security Certification",
    },
    frontContent: "QCSC:\nQuantum Communication\nSecurity Certification",
    backContent: {
      ko: `양자 통신 보안 전문가 자격증(QCSC)은 양자 통신 시스템의 보안성을 보장하는 데 필요한 지식과 기술을 제공합니다.\n이 자격증은 양자 채널의 보안 분석, 양자 키 분배(QKD) 기술, 그리고 양자 통신 네트워크의 안전한 운영 방법을 다룹니다.\nQCSC는 양자 통신의 보안을 담당하는 전문가로서의 역량을 강화합니다.`,
      en: `The Quantum Communication Security Certification (QCSC) provides the knowledge and skills necessary to ensure the security of quantum communication systems. This certification covers the security analysis of quantum channels, Quantum Key Distribution (QKD) techniques, and secure operation methods of quantum communication networks. The QCSC enhances the capabilities of professionals responsible for the security of quantum communications.`,
    },
    class: "양자 내성 암호 PQC",
  },
  {
    id: 10,
    title: {
      ko: "암호 공격 전문가 자격증",
      en: "Certified Expert in Cryptographic Attacks",
    },
    frontContent: "CEAC:\nCertified Expert in\nCryptographic Attacks",
    backContent: {
      ko: `암호 공격 전문가 자격증(CEAC)은 암호화 시스템에 대한 다양한 공격 기법과 방어 전략에 대한 심층적인 지식을 제공합니다.\n이 자격증은 암호 알고리즘의 취약성, 암호 해독 방법, 사이드 채널 공격, 그리고 최신 암호 공격 기법 등을 다룹니다.\nCEAC는 암호화 시스템의 보안성을 테스트하고 강화하는 데 필요한 전문가로서의 능력을 갖추게 합니다.`,
      en: `The Certified Expert in Cryptographic Attacks (CEAC) certification provides deep knowledge of various attack techniques and defense strategies for cryptographic systems. This certification covers vulnerabilities in cryptographic algorithms, methods of decryption, side-channel attacks, and the latest cryptographic attack techniques. CEAC equips professionals with the expertise necessary to test and enhance the security of cryptographic systems.`,
    },
    class: "암호 공격 및 취약성 분석",
  },
  {
    id: 11,
    title: {
      ko: "암호 취약성 분석 자격증",
      en: "Cryptographic Vulnerability Analysis Certification",
    },
    frontContent: "CVAC:\nCryptographic Vulnerability\nAnalysis Certification",
    backContent: {
      ko: `암호 취약성 분석 자격증(CVAC)은 암호화 시스템의 취약성을 식별하고 분석하는 능력을 개발하는 데 중점을 둡니다.\n이 자격증은 암호화 알고리즘의 보안 평가, 취약성 분석 기법, 보안 취약점 탐지 및 수정 방법 등을 포함합니다.\nCVAC는 암호화 시스템의 안전성을 유지하기 위한 필수적인 분석 능력을 제공합니다.`,
      en: `The Cryptographic Vulnerability Analysis Certification (CVAC) focuses on developing the ability to identify and analyze vulnerabilities in cryptographic systems. This certification includes security evaluation of cryptographic algorithms, techniques for vulnerability analysis, and methods for detecting and remedying security vulnerabilities. CVAC provides essential analytical capabilities to maintain the security of cryptographic systems.`,
    },
    class: "암호 공격 및 취약성 분석",
  },
  {
    id: 12,
    title: {
      ko: "암호 공격 이론 및 실습 자격증",
      en: "Cryptographic Attack Theory and Practice Certification",
    },
    frontContent:
      "CATPC:\nCryptographic Attack Theory\nand Practice Certification",
    backContent: {
      ko: `암호 공격 이론 및 실습 자격증(CATPC)은 이론적인 암호 공격 기법과 실제 적용 사례를 모두 다룹니다.\n이 자격증은 다양한 암호 공격 시나리오, 암호화 시스템의 취약성 실습, 공격 방어 전략 등을 포함하여, 실무에서 활용 가능한 암호 공격 지식을 제공합니다.\nCATPC는 암호화 시스템의 보안을 실질적으로 테스트하고 강화하는 데 필요한 실전 경험을 제공합니다.`,
      en: `The Cryptographic Attack Theory and Practice Certification (CATPC) covers both theoretical cryptographic attack techniques and real-world applications. This certification includes various cryptographic attack scenarios, vulnerability exercises in cryptographic systems, defense strategies against attacks, and provides practical cryptographic attack knowledge applicable in professional settings. CATPC offers practical experience necessary for effectively testing and enhancing the security of cryptographic systems.`,
    },
    class: "암호 공격 및 취약성 분석",
  },
  {
    id: 13,
    title: {
      ko: "암호 침해 분석 전문가 자격증",
      en: "Certified Breaker in Applied Cryptography",
    },
    frontContent: "CBAC:\nCertified Breaker in\nApplied Cryptography",
    backContent: {
      ko: `암호 침해 분석 전문가 자격증(CBAC)은 실제 환경에서 암호화 시스템의 보안을 평가하고 침해할 수 있는 능력을 개발합니다.\n이 자격증은 암호 해독 기법, 실제 공격 시나리오 분석, 침해 사례 연구 등을 포함하여, 실무에서의 암호 침해 분석 능력을 갖추게 합니다.\nCBAC는 암호화 시스템의 취약성을 실제로 테스트하고 강화하는 전문가로 성장할 수 있게 합니다.`,
      en: `The Certified Breaker in Applied Cryptography (CBAC) develops the ability to evaluate and breach the security of cryptographic systems in real-world environments. This certification includes cryptographic decryption techniques, analysis of real-world attack scenarios, and breach case studies, equipping you with the ability to analyze cryptographic breaches in practical settings. CBAC enables you to become an expert in practically testing and strengthening the vulnerabilities of cryptographic systems.`,
    },
    class: "암호 공격 및 취약성 분석",
  },
];

export default LicenseList;
