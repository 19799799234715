import { Miti, Youtube } from "../assets/index";
import i18n from "i18next";
import { useRecoilState } from "recoil";
import { langState } from "../recoil/atoms/langState";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const [lang, setLang]: any = useRecoilState(langState);

  return (
    <div className="Footer">
      <div className="LogoContainer">
        <div className="MitiBox">
          <img src={Miti} className="MitiLogo" />
          <span>{t(`Footer.Name`)}</span>
        </div>

        <img src={Youtube} className="YoutubeLogo" />
      </div>
      <div className="Description">
        <span>
          {t(`Footer.Address`)} <br />
        </span>

        <span>
          Tel : 031-995-6409 <br />
        </span>

        <span>
          E-mail : info@miti.kr <br />
        </span>

        <div className="Policy">
          <span className="Privacy">{t(`Footer.Privacy`)}</span>{" "}
          <span className="Middle">|</span>
          <span className="EmailReject">{t(`Footer.Email`)}</span>
        </div>

        <span className="Copyright">
          Copyright © 2024 Mathematics-based Industrial Technology Innovation
          Academic Society All right Reserved
        </span>
      </div>
    </div>
  );
};

export default Footer;
