import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./style/index.scss";
import {
  Main,
  Header,
  Footer,
  Course,
  License,
  LicenseDetail,
  CourseDetail,
  View,
} from "./page";
import { ScrollToTop } from "./components";
import { RecoilRoot } from "recoil";

function App() {
  return (
    <div className="App">
      <RecoilRoot>
        <BrowserRouter>
          <ScrollToTop />
          <Header />
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/course" element={<Course />} />
            <Route path="/course/:id" element={<CourseDetail />} />
            <Route path="/license" element={<License />} />
            <Route path="/license/:id" element={<LicenseDetail />} />
            <Route path="/view/:id/:sub/:video" element={<View />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </RecoilRoot>
    </div>
  );
}

export default App;
