import i18n from "i18next";
import { useRecoilState } from "recoil";
import { langState } from "../../recoil/atoms/langState";
import { useTranslation } from "react-i18next";

const GoToApplicate = () => {
  const { t } = useTranslation();
  const [lang, setLang]: any = useRecoilState(langState);

  return (
    <div className="GoToApplicate">
      <span>{t(`main.GoToApplicate.Text`)}</span>
      <div
        className="BtnBox"
        onClick={() => {
          lang === "ko"
            ? window.open("https://forms.gle/xzvc1ZhNjJB1kfLUA")
            : window.open("https://forms.gle/9Svf9KHzEkFrw5jw7");
        }}
      >
        <span>{t(`main.GoToApplicate.Button`)}</span>
      </div>
    </div>
  );
};
export default GoToApplicate;
