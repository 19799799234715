import { AcumenLabHR } from "../../assets";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { langState } from "../../recoil/atoms/langState";

const DescriptionSection = () => {
  const { t } = useTranslation();
  const [lang, setLang]: any = useRecoilState(langState);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  return (
    <div className="DescriptionSection">
      <div className="InnerContainer">
        <div className="LogoContainer">
          <img src={AcumenLabHR} />
        </div>
        <div className="DescriptionContainer">
          <span>
            {t(`main.DescriptionSection.Description1`)} <br />
            <br />
            {t(`main.DescriptionSection.Description2`)} <br />
            <br />
            {t(`main.DescriptionSection.Description3`)} <br />
            <br />
            <br />
            {t(`main.DescriptionSection.Description4`)} <br />
          </span>
        </div>
      </div>
    </div>
  );
};

export default DescriptionSection;
