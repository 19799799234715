import React from "react";
import { Breadcrumb } from "../components";
import LicenseList, { LicenseType } from "../assets/License/LicenseList";
import { useNavigate, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { langState } from "../recoil/atoms/langState";
import { useTranslation } from "react-i18next";
import CourseList, { CourseType } from "../assets/Course/CourseList";

const LicenseDetail = () => {
  const nav = useNavigate();
  const { t } = useTranslation();
  const loc = useLocation();
  const cardData: LicenseType[] = LicenseList;
  const courseData: CourseType[] = CourseList;

  const [lang, setLang] = useRecoilState<"ko" | "en">(langState);

  const path = parseInt(loc.pathname.split("/")[2], 10) - 1;
  const data = cardData[path];

  if (!data) {
    return <div>해당하는 자격증이 없습니다.</div>;
  }

  const renderLicenses = (start: number, end: number) => {
    const licensesToRender = cardData
      .slice(start, end)
      .filter((license) => license.id !== data.id);

    return (
      <>
        {licensesToRender.map((data) => (
          <div className="LicenseOuter" key={data.id}>
            <div
              className="LicenseBox"
              onClick={() => {
                nav(`/license/${data.id}`);
              }}
            >
              <span className="Title">{data.title[lang]}</span>
              <span className="Desc">
                {lang === "en"
                  ? data.title["ko"]
                  : data.frontContent.split("\n").map((line, idx) => (
                      <React.Fragment key={idx}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
              </span>
            </div>
          </div>
        ))}
        {[...Array(5 - licensesToRender.length)].map((_, idx) => (
          <div className="EmptyBox" key={idx}></div>
        ))}
      </>
    );
  };

  const renderRelatedLicenses = () => {
    if (data.class === "일반 암호 시스템") {
      return renderLicenses(0, 4);
    } else if (data.class === "양자 내성 암호 PQC") {
      return renderLicenses(4, 9);
    } else if (data.class === "암호 공격 및 취약성 분석") {
      return renderLicenses(9, 13);
    } else {
      return null;
    }
  };

  const classTranslate = (cl: string) => {
    if (cl === "일반 암호 시스템") {
      return "General Cryptography System";
    } else if (cl === "양자 내성 암호 PQC") {
      return "Post-Quantum Cryptography PQC";
    } else if (cl === "암호 공격 및 취약성 분석") {
      return "Cryptographic Attack and Vulnerability Analysis";
    } else {
      return null;
    }
  };

  return (
    <div className="LicenseDetail">
      <div className="InnerContainer">
        <Breadcrumb />
        <div className="TopTitle">
          <span>{data.title[lang]}</span>
          <div className="ListBtn" onClick={() => nav("/license")}>
            {t(`LicenseDetail.Btn`)}
          </div>
        </div>

        <div className="Body">
          <span className="Summary">{t(`LicenseDetail.Summary`)}</span>
          <span className="Desc">{data.backContent[lang]}</span>

          <table>
            <tbody>
              <tr>
                <td className="LeftCell">{t(`LicenseDetail.Table1`)}</td>
                <td className="RightCell">{data.title[lang]}</td>
              </tr>
              <tr>
                <td className="LeftCell">{t(`LicenseDetail.Table2`)}</td>
                <td className="RightCell">
                  {lang === "ko" ? data.frontContent : data.title["ko"]}
                </td>
              </tr>
              <tr>
                <td className="LeftCell">{t(`LicenseDetail.Table3`)}</td>
                <td className="RightCell">
                  {lang === "ko" ? data.class : classTranslate(data.class)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="Relate">
          <div className="SubTitle">{t(`LicenseDetail.RelateCert`)}</div>
          <div className="Licenses">{renderRelatedLicenses()}</div>
        </div>

        <div className="Relate">
          <div className="SubTitle">{t(`LicenseDetail.RelateCour`)}</div>

          <div className="CourseLists">
            {CourseList.slice(0, 2).map((index) => (
              <div className="CourseContainer">
                <div className="ThumbnailBox"></div>
                <div className="DescBox">
                  <span className="DescTitle">기초 암호학</span>
                  <span className="DescDesc">3과목 총 15강</span>
                  <div className="DescBtn">강의 보기</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LicenseDetail;
