import React, { useState, useEffect, useRef } from "react";
import { LicenseIcon } from "../../assets";
import LicenseList from "../../assets/License/LicenseList";
import { useRecoilState } from "recoil";
import { langState } from "../../recoil/atoms/langState";
import { useTranslation } from "react-i18next";

interface Card {
  id: number;
  title: {
    [key: string]: string;
  };
  frontContent: string;
  backContent: {
    [key: string]: string;
  };
}

const LicenseSection = () => {
  const cardData: Card[] = LicenseList;
  const { t } = useTranslation();
  const [lang, setLang]: any = useRecoilState(langState);

  const [flippedStates, setFlippedStates] = useState(cardData.map(() => false));
  const [activeTab, setActiveTab] = useState(0);
  const underlineRef = useRef<HTMLDivElement>(null);

  const handleCardClick = (index: number) => {
    setFlippedStates((prevState) => {
      const newStates = [...prevState];
      newStates[index] = !newStates[index];
      return newStates;
    });
  };

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  useEffect(() => {
    const updateUnderline = () => {
      if (underlineRef.current) {
        const underlineWidth = underlineRef.current.offsetWidth;
        underlineRef.current.style.transform = `translateX(${
          activeTab * underlineWidth - underlineWidth
        }px)`;
      }
    };

    updateUnderline();
    window.addEventListener("resize", updateUnderline);

    return () => {
      window.removeEventListener("resize", updateUnderline);
    };
  }, [activeTab]);

  const tabs = [
    t(`main.LicenseSection.Tab1`),
    t(`main.LicenseSection.Tab2`),
    t(`main.LicenseSection.Tab3`),
  ];

  const isCardVisible = (index: number) => {
    if (activeTab === 0) return index >= 0 && index <= 3;
    if (activeTab === 1) return index >= 4 && index <= 8;
    if (activeTab === 2) return index >= 9 && index <= 12;
    return false;
  };

  return (
    <div className="LicenseSection">
      <div className="InnerContainer">
        <div className="TitleContainer">
          <span>{t(`main.LicenseSection.Title`)}</span>
        </div>

        <div className="MenuContainer">
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={`MenuBox ${activeTab === index ? "active" : ""}`}
              onClick={() => handleTabClick(index)}
            >
              {tab}
            </div>
          ))}
          <div className="underline" ref={underlineRef}></div>
        </div>

        <div className="CardContainer">
          {cardData.map((card, index) => (
            <div
              className={`FlipableCard ${
                isCardVisible(index) ? "" : "HideCard"
              }`}
              key={card.id}
              onClick={() => handleCardClick(index)}
            >
              <div className={`Inner ${flippedStates[index] ? "flipped" : ""}`}>
                <div className="FrontSide">
                  <img src={LicenseIcon} alt="License Icon" />

                  <div className="DescriptionBox">
                    <div className="Title">{card.title[lang]}</div>
                    <span className="Description">
                      {(lang === "ko" ? card.frontContent : card.title.ko)
                        .split("\n")
                        .map((line, i) => (
                          <React.Fragment key={i}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                    </span>
                  </div>
                </div>

                <div className="BackSide">
                  <div className={`Text ${lang === "ko" ? "" : "enFont"}`}>
                    <span className="Title">{card.title[lang]}</span>
                    <span className="Description">
                      {card.backContent[lang].split("\n").map((line, i) => (
                        <React.Fragment key={i}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LicenseSection;
