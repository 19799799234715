import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { LangIcon, AcumenLab, MenuBtnIcon } from "../assets/index";
import { useRecoilState } from "recoil";
import { langState } from "../recoil/atoms/langState";
import i18next, { t } from "i18next";

function Header() {
  let nav = useNavigate();
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [lang, setLang]: any = useRecoilState(langState);

  const menuRef = useRef<any>(null);
  const menuBtnRef = useRef<any>(null);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClickOutside = (event: any) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      menuBtnRef.current &&
      !menuBtnRef.current.contains(event.target)
    ) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  const changeLanguage = (language: string) => {
    i18next.changeLanguage(language);
    setLang(language);
  };

  const isMainPage = location.pathname === "/";

  return (
    <div className="Outer">
      <div className={`header ${!isMainPage || isScrolled ? "scrolled" : ""}`}>
        <div
          className="left-container"
          onClick={() => {
            nav("/");
          }}
        >
          <img src={AcumenLab} />
        </div>

        <div className="right-container">
          <div className="lang-container">
            <img src={LangIcon} />
            <span
              className={`lang-choice ${lang === "en" ? "active" : ""}`}
              onClick={() => {
                changeLanguage("en");
              }}
            >
              ENGLISH
            </span>
            <span className="middle"> | </span>
            <span
              className={`lang-choice ${lang !== "en" ? "active" : ""}`}
              onClick={() => {
                changeLanguage("ko");
              }}
            >
              한국어
            </span>
          </div>

          <div className="menu-container">
            <div
              className="menu"
              onClick={() => {
                nav("/course");
              }}
            >
              <span>{t(`Header.Course`)}</span>
            </div>
            <div
              className="menu"
              onClick={() => {
                nav("/license");
              }}
            >
              <span>{t(`Header.Certification`)}</span>
            </div>
            {!isMainPage && (
              <div
                className="menu-applicate"
                onClick={() => {
                  lang === "ko"
                    ? window.open("https://forms.gle/xzvc1ZhNjJB1kfLUA")
                    : window.open("https://forms.gle/9Svf9KHzEkFrw5jw7");
                }}
              >
                <span>{t(`Header.Button`)}</span>
              </div>
            )}
          </div>
        </div>

        <div className="mobileMenu">
          <div className="mobileInner">
            <img
              src={AcumenLab}
              onClick={() => {
                nav("/");
              }}
            />
            <img
              className="menuBtn"
              src={MenuBtnIcon}
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              ref={menuBtnRef}
            />
          </div>
        </div>
      </div>

      {isMenuOpen && (
        <div className={`expand ${isScrolled ? "scrolled" : ""}`} ref={menuRef}>
          <div className="Links">
            <span
              onClick={() => {
                nav("/course");
                setIsMenuOpen(false);
              }}
            >
              {t(`Header.Course`)}
            </span>
            <span
              onClick={() => {
                nav("/license");
                setIsMenuOpen(false);
              }}
            >
              {t(`Header.Certification`)}
            </span>
          </div>
          <div className="Lang">
            <span
              className={`lang-choice ${lang === "en" ? "active" : ""}`}
              onClick={() => {
                changeLanguage("en");
                setIsMenuOpen(false);
              }}
            >
              <img src={LangIcon} /> ENGLISH
            </span>
            <span className="Middle">|</span>
            <span
              className={`lang-choice ${lang !== "en" ? "active" : ""}`}
              onClick={() => {
                changeLanguage("ko");
                setIsMenuOpen(false);
              }}
            >
              한국어
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;
