import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Breadcrumb } from "../components";
import { SearchIcon } from "../assets";
import { useRecoilState } from "recoil";
import { langState } from "../recoil/atoms/langState";
import { useTranslation } from "react-i18next";
import LicenseList, { LicenseType } from "../assets/License/LicenseList";

const License = () => {
  let nav = useNavigate();
  const { t } = useTranslation();
  const [lang, setLang] = useRecoilState<"ko" | "en">(langState);
  const [searchTerm, setSearchTerm] = useState("");

  const cardData = LicenseList.filter((data: LicenseType) =>
    data.title[lang].toLowerCase().includes(searchTerm.toLowerCase())
  );

  const renderLicenses = (start: number, end: number) => {
    const licensesToRender = cardData.slice(start, end);

    return (
      <>
        {licensesToRender.map((data: LicenseType) => (
          <div
            className="LicenseBox"
            onClick={() => {
              nav(`/license/${data.id}`);
            }}
            key={data.id}
          >
            <span className="Title">{data.title[lang]}</span>
            <span className="Desc">
              {lang === "en"
                ? data.title["ko"]
                : data.frontContent.split("\n").map((line, idx) => (
                    <React.Fragment key={idx}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
            </span>
          </div>
        ))}
        {[...Array(5 - licensesToRender.length)].map((_, idx) => (
          <div className="EmptyBox" key={idx}></div>
        ))}
      </>
    );
  };

  return (
    <div className="License">
      <div className="InnerContainer">
        <Breadcrumb />

        <div className="SearchContainer">
          <div className="SearchBar">
            <img src={SearchIcon} alt="Search Icon" />
            <input
              placeholder={t(`License.SearchPlaceholder`)}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>

        <div className="LicenseContainer">
          <div className="LicenseTitle">{t(`License.Tab1`)}</div>
          <div className="Licenses">{renderLicenses(0, 4)}</div>
        </div>

        <div className="LicenseContainer">
          <div className="LicenseTitle">{t(`License.Tab2`)}</div>
          <div className="Licenses">{renderLicenses(4, 9)}</div>
        </div>

        <div className="LicenseContainer">
          <div className="LicenseTitle">{t(`License.Tab3`)}</div>
          <div className="Licenses">{renderLicenses(9, 13)}</div>
        </div>
      </div>
    </div>
  );
};

export default License;
