import React, { useState, useEffect } from "react";

interface ScrollButtonProps {
  currentPage: number;
  handleButtonClick: (page: number) => void;
}

const ScrollButton = ({
  currentPage,
  handleButtonClick,
}: ScrollButtonProps) => {
  const titles: { [key: number]: string } = {
    0: "Home",
    1: "AcumenLab",
    2: "Courses",
    3: "Certificate",
  };

  return (
    <div className="ScrollButton">
      <div className="Wrapper">
        <div className="Title">{titles[currentPage]}</div>
        <div className="ScrollStatusWrapper">
          <button
            className={currentPage === 0 ? "ActiveBtn" : "Btn"}
            onClick={() => handleButtonClick(0)}
          />
          <button
            className={currentPage === 1 ? "ActiveBtn" : "Btn"}
            onClick={() => handleButtonClick(1)}
          />
          <button
            className={currentPage === 2 ? "ActiveBtn" : "Btn"}
            onClick={() => handleButtonClick(2)}
          />
          <button
            className={currentPage === 3 ? "ActiveBtn" : "Btn"}
            onClick={() => handleButtonClick(3)}
          />
        </div>
      </div>
    </div>
  );
};

export default ScrollButton;
