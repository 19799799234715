import React from "react";
import { useRecoilState } from "recoil";
import { langState } from "../recoil/atoms/langState";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LicenseList from "../assets/License/LicenseList";
import CourseList from "../assets/Course/CourseList";

const Breadcrumb = () => {
  const [lang, setLang] = useRecoilState<"ko" | "en">(langState);
  const { t } = useTranslation();
  const loc = useLocation();
  const nav = useNavigate();
  const path = loc.pathname.split("/")[1];
  const { id, sub, video } = useParams<{
    id: string;
    sub: string;
    video: string;
  }>();

  let pageName = "";

  switch (path) {
    case "license":
      pageName = t(`Breadcrumb.Certification`);
      break;
    case "course":
      pageName = t(`Breadcrumb.Course`);
      break;
    case "view":
      pageName = t(`Breadcrumb.Course`);
      break;
    default:
      break;
  }

  const LicenseDisplay = () => {
    const licenseId = parseInt(id || "-1", 10);
    if (!isNaN(licenseId) && licenseId >= 0 && licenseId < LicenseList.length) {
      return (
        <>
          <span className="MiddleArrow">{">"}</span>
          <span>{LicenseList[licenseId].title[lang]}</span>
        </>
      );
    }
    return null;
  };

  const CourseDisplay = () => {
    const courseId = parseInt(id || "-1", 10);
    if (!isNaN(courseId) && courseId >= 0 && courseId < CourseList.length) {
      return (
        <>
          <span className="MiddleArrow">{">"}</span>
          <span
            onClick={() => {
              nav(`/course/${courseId}`);
            }}
          >
            {CourseList[courseId].title[lang]}
          </span>
        </>
      );
    }
    return null;
  };

  const ViewDisplay = () => {
    const courseId = parseInt(id || "-1", 10);
    const subjectId = parseInt(sub || "-1", 10);
    const videoId = parseInt(video || "-1", 10);

    if (
      !isNaN(courseId) &&
      courseId >= 0 &&
      courseId < CourseList.length &&
      subjectId > 0 &&
      subjectId <= CourseList[courseId].subject.length
    ) {
      return (
        <div className="ViewSpan">
          <span className="MiddleArrow">{">"}</span>

          <span
            onClick={() => {
              nav(`/course/${courseId}`);
            }}
          >
            {subjectId}.{" "}
            {CourseList[courseId].subject[subjectId - 1].subjectTitle[lang]}
          </span>
          <span className="MiddleArrow">{">"}</span>
          <span>{t(`Breadcrumb.Lecture`, { count: videoId })}</span>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="Breadcrumb">
      <div className="InnerSpan">
        <span
          onClick={() => {
            nav(`/`);
          }}
        >
          Home
        </span>
        <span className="MiddleArrow">{">"}</span>
        <span
          onClick={() => {
            nav(`/${path}`);
          }}
        >
          {pageName}
        </span>
        {path === "license" ? <LicenseDisplay /> : <CourseDisplay />}
      </div>
      {path === "view" && <ViewDisplay />}
    </div>
  );
};

export default Breadcrumb;
