import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BookIcon, SearchIcon } from "../assets";
import { Breadcrumb } from "../components";
import { useRecoilState } from "recoil";
import { langState } from "../recoil/atoms/langState";
import CourseList, { CourseType } from "../assets/Course/CourseList";
import { useTranslation } from "react-i18next";

const Course = () => {
  let nav = useNavigate();
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const [lang, setLang] = useRecoilState<"ko" | "en">(langState);

  const courseData: CourseType[] = CourseList.filter((data: CourseType) =>
    data.title[lang]
      .toLocaleLowerCase()
      .includes(searchTerm.toLocaleLowerCase())
  );

  return (
    <div className="Course">
      <div className="InnerContainer">
        <Breadcrumb />

        <div className="TopTitle">
          <span>{t(`Course.Title`)}</span>
        </div>
        <div className="SearchContainer">
          <div className="SearchBar">
            <img src={SearchIcon} alt="Search Icon" />
            <input
              placeholder={t(`Course.SearchPlaceholder`)}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <div className="CourseOuter">
          <div className="Courses">
            {courseData.map((course: CourseType) => (
              <div key={course.id} className="CourseContainer">
                <div className="ThumbnailBox">
                  <img
                    src={`https://img.youtube.com/vi/${
                      course.subject[0].courses[0].link.split("=")[1]
                    }/mqdefault.jpg`}
                  />
                </div>
                <div className={`DescBox ${lang === "ko" ? "" : "enFont"}`}>
                  <div className="UpperDesc">
                    <span className="DescTitle">{course.title[lang]}</span>
                    <span className="DescDesc">
                      {t(`Course.Sub`, {
                        count: course.subject.length,
                      })}{" "}
                      |{" "}
                      {t(`Course.Lecture`, {
                        count: course.subject.reduce(
                          (total, subj) => total + subj.courses.length,
                          0
                        ),
                      })}
                    </span>
                  </div>

                  <div className="UnderDesc">
                    {course.subject.slice(0, 3).map((subject, idx) => (
                      <span key={subject.subjectId} className="SubjectDesc">
                        <span className="Bold">
                          {t(`Course.nSub`, {
                            count: idx + 1,
                          })}
                        </span>
                        <span className="Middle">|</span>
                        <span>{subject.subjectTitle[lang]}</span>
                      </span>
                    ))}
                  </div>
                  <div
                    className="DescBtn"
                    onClick={() => {
                      nav(`/course/${course.id}`);
                    }}
                  >
                    <img src={BookIcon} />
                    {t(`Course.Btn`)}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Course;
