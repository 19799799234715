import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { langState } from "../../recoil/atoms/langState";
import CourseList from "../../assets/Course/CourseList";
import { CourseType } from "../../assets/Course/CourseList";

const CourseSection = () => {
  const nav = useNavigate();
  const { t } = useTranslation();
  const [lang, setLang] = useRecoilState<"ko" | "en">(langState);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 1040);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1040);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const displayCourses = isMobile
    ? CourseList.slice(0, 4)
    : CourseList.slice(0, 6);

  return (
    <div className="CourseSection">
      <div className="InnerContainer">
        <div className="TitleContainer">
          <span>{t(`main.CourseSection.Title`)}</span>
        </div>

        <div className="CourseContainer">
          {displayCourses.map((course: CourseType, index: number) => (
            <div
              className="CourseBox"
              key={index}
              onClick={() => {
                nav(`/course/${index + 1}`);
              }}
            >
              <div className="Inner">
                <div className="Thumbnail">
                  <img
                    src={`https://img.youtube.com/vi/${
                      course.subject[0].courses[0].link.split("=")[1]
                    }/mqdefault.jpg`}
                  />{" "}
                </div>
                <div className={`Text ${lang === "ko" ? "" : "enFont"}`}>
                  <span className="Title">{course.title[lang]}</span>
                  <span className="Description">
                    {t(`main.CourseSection.Sub`, {
                      count: course.subject.length,
                    })}{" "}
                    |{" "}
                    {t(`main.CourseSection.Lecture`, {
                      count: course.subject.reduce(
                        (total, subj) => total + subj.courses.length,
                        0
                      ),
                    })}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div
          className="GoToCourse"
          onClick={() => {
            nav("/course");
          }}
        >
          {t(`main.CourseSection.Button`)} {">>"}
        </div>
      </div>
    </div>
  );
};

export default CourseSection;
