export interface CourseType {
  id: number;
  title: {
    ko: string;
    en: string;
  };
  subject: {
    subjectId: number;
    subjectTitle: {
      ko: string;
      en: string;
    };
    courses: {
      courseTitle: {
        ko: string;
        en: string;
      };
      duration: string;
      link: string;
    }[];
  }[];
}

const CourseList: CourseType[] = [
  {
    id: 1,
    title: {
      ko: "기초 암호학",
      en: "Basic Cryptography",
    },
    subject: [
      {
        subjectId: 1,
        subjectTitle: {
          ko: "암호학의 기본 원리와 역사",
          en: "Fundamental Principles and History of Cryptography",
        },
        courses: [
          {
            courseTitle: {
              ko: "암호학의 기본 원리 1",
              en: "Fundamental Principles of Cryptography 1",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
          {
            courseTitle: {
              ko: "암호학의 기본 원리 2",
              en: "Fundamental Principles of Cryptography 2",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=zGrTT4k1-yc",
          },
          {
            courseTitle: {
              ko: "암호학의 기본 원리 3",
              en: "Fundamental Principles of Cryptography 3",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=r1zw_eM2aiQ",
          },
          {
            courseTitle: {
              ko: "암호학의 역사 1",
              en: "History of Cryptography 1",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=63bdCwJmRP4",
          },
          {
            courseTitle: {
              ko: "암호학의 역사 2",
              en: "History of Cryptography 2",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=XXseiON9CV0",
          },
        ],
      },
      {
        subjectId: 2,
        subjectTitle: {
          ko: "대칭 키 암호와 공개 키 암호 시스템",
          en: "Symmetric Key Encryption and Public Key Encryption Systems",
        },
        courses: [
          {
            courseTitle: {
              ko: "대칭 키 암호 시스템 1",
              en: "Symmetric Key Encryption System 1",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
          {
            courseTitle: {
              ko: "대칭 키 암호 시스템 2",
              en: "Symmetric Key Encryption System 2",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
          {
            courseTitle: {
              ko: "공개 키 암호 시스템 1",
              en: "Public Key Encryption System 1",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
          {
            courseTitle: {
              ko: "공개 키 암호 시스템 2",
              en: "Public Key Encryption System 2",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
        ],
      },
      {
        subjectId: 3,
        subjectTitle: {
          ko: "해시 함수와 메시지 인증 코드 (MAC)",
          en: "Hash Functions and Message Authentication Code (MAC)",
        },
        courses: [
          {
            courseTitle: {
              ko: "해시 함수 1",
              en: "Hash Functions 1",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
          {
            courseTitle: {
              ko: "해시 함수 2",
              en: "Hash Functions 2",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
          {
            courseTitle: {
              ko: "메시지 인증 코드 1",
              en: "Message Authentication Code 1",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
          {
            courseTitle: {
              ko: "메시지 인증 코드 2",
              en: "Message Authentication Code 2",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    title: {
      ko: "수학적 공간",
      en: "Mathematical Space",
    },
    subject: [
      {
        subjectId: 1,
        subjectTitle: {
          ko: "수학적 추론과 증명",
          en: "Mathematical Reasoning and Proof",
        },
        courses: [
          {
            courseTitle: {
              ko: "수학적 추론 1",
              en: "Mathematical Reasoning 1",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
          {
            courseTitle: {
              ko: "수학적 추론 2",
              en: "Mathematical Reasoning 2",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
        ],
      },
      {
        subjectId: 2,
        subjectTitle: {
          ko: "유한 체와 유리수 이론",
          en: "Finite Field and Rational Number Theory",
        },
        courses: [
          {
            courseTitle: {
              ko: "유한 체 1",
              en: "Finite Field 1",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
          {
            courseTitle: {
              ko: "유리수 이론 1",
              en: "Rational Number Theory 1",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
        ],
      },
      {
        subjectId: 3,
        subjectTitle: {
          ko: "선형 대수와 행렬 연산",
          en: "Linear Algebra and Matrix Operations",
        },
        courses: [
          {
            courseTitle: {
              ko: "선형 대수 1",
              en: "Linear Algebra 1",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
          {
            courseTitle: {
              ko: "행렬 연산 1",
              en: "Matrix Operations 1",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
        ],
      },
    ],
  },
  {
    id: 3,
    title: {
      ko: "코딩 이론",
      en: "Coding Theory",
    },
    subject: [
      {
        subjectId: 1,
        subjectTitle: {
          ko: "코딩 이론의 기본 개념과 적용",
          en: "Basic Concepts and Applications of Coding Theory",
        },
        courses: [
          {
            courseTitle: {
              ko: "코딩 이론 1",
              en: "Coding Theory 1",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
          {
            courseTitle: {
              ko: "코딩 이론 2",
              en: "Coding Theory 2",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
        ],
      },
      {
        subjectId: 2,
        subjectTitle: {
          ko: "오류 정정 코드와 순열 코드",
          en: "Error-Correcting Code and Permutation Code",
        },
        courses: [
          {
            courseTitle: {
              ko: "오류 정정 코드 1",
              en: "Error-Correcting Code 1",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
          {
            courseTitle: {
              ko: "순열 코드 1",
              en: "Permutation Code 1",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
        ],
      },
      {
        subjectId: 3,
        subjectTitle: {
          ko: "정보 이론과 암호화 간의 관계",
          en: "The Relationship between Information Theory and Encryption",
        },
        courses: [
          {
            courseTitle: {
              ko: "정보 이론과 암호화 1",
              en: "Information Theory and Encryption 1",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
          {
            courseTitle: {
              ko: "정보 이론과 암호화 2",
              en: "Information Theory and Encryption 2",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
        ],
      },
    ],
  },
  {
    id: 4,
    title: {
      ko: "타원 곡선 이론",
      en: "Elliptic Curve Theory",
    },
    subject: [
      {
        subjectId: 1,
        subjectTitle: {
          ko: "타원 곡선의 기본 원리와 수학적 배경",
          en: "Basic Principles and Mathematical Background of Elliptic Curves",
        },
        courses: [
          {
            courseTitle: {
              ko: "타원 곡선 기본 원리 1",
              en: "Basic Principles of Elliptic Curves 1",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
          {
            courseTitle: {
              ko: "타원 곡선 기본 원리 2",
              en: "Basic Principles of Elliptic Curves 2",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
        ],
      },
      {
        subjectId: 2,
        subjectTitle: {
          ko: "타원 곡선 암호화의 원리와 적용",
          en: "Principles and Applications of Elliptic Curve Encryption",
        },
        courses: [
          {
            courseTitle: {
              ko: "타원 곡선 암호화 원리 1",
              en: "Principles of Elliptic Curve Encryption 1",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
          {
            courseTitle: {
              ko: "타원 곡선 암호화 원리 2",
              en: "Principles of Elliptic Curve Encryption 2",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
        ],
      },
      {
        subjectId: 3,
        subjectTitle: {
          ko: "타원 곡선 기반의 암호화 알고리즘과 보안 장점",
          en: "Elliptic Curve-based Encryption Algorithms and Security Benefits",
        },
        courses: [
          {
            courseTitle: {
              ko: "타원 곡선 암호화 알고리즘 1",
              en: "Elliptic Curve Encryption Algorithms 1",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
          {
            courseTitle: {
              ko: "타원 곡선 암호화 알고리즘 2",
              en: "Elliptic Curve Encryption Algorithms 2",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
        ],
      },
    ],
  },
  {
    id: 5,
    title: {
      ko: "암호 해독과 공격",
      en: "Decryption and Attacks",
    },
    subject: [
      {
        subjectId: 1,
        subjectTitle: {
          ko: "전통적인 암호 시스템의 공격 기법",
          en: "Traditional Cryptosystem Attack Techniques",
        },
        courses: [
          {
            courseTitle: {
              ko: "암호 시스템 공격 기법 1",
              en: "Cryptosystem Attack Techniques 1",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
          {
            courseTitle: {
              ko: "암호 시스템 공격 기법 2",
              en: "Cryptosystem Attack Techniques 2",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
        ],
      },
      {
        subjectId: 2,
        subjectTitle: {
          ko: "공개 키 인프라 (PKI)와 중간자 공격",
          en: "Public Key Infrastructure (PKI) and Man-in-the-Middle Attacks",
        },
        courses: [
          {
            courseTitle: {
              ko: "공개 키 인프라 1",
              en: "Public Key Infrastructure 1",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
          {
            courseTitle: {
              ko: "중간자 공격 1",
              en: "Man-in-the-Middle Attacks 1",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
        ],
      },
    ],
  },
  {
    id: 6,
    title: {
      ko: "정보 이론",
      en: "Information Theory",
    },
    subject: [
      {
        subjectId: 1,
        subjectTitle: {
          ko: "정보 이론의 기본 개념과 역사",
          en: "Basic Concepts and History of Information Theory",
        },
        courses: [
          {
            courseTitle: {
              ko: "정보 이론 기본 개념 1",
              en: "Basic Concepts of Information Theory 1",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
          {
            courseTitle: {
              ko: "정보 이론 기본 개념 2",
              en: "Basic Concepts of Information Theory 2",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
        ],
      },
      {
        subjectId: 2,
        subjectTitle: {
          ko: "엔트로피와 정보 압축",
          en: "Entropy and Information Compression",
        },
        courses: [
          {
            courseTitle: {
              ko: "엔트로피 1",
              en: "Entropy 1",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
          {
            courseTitle: {
              ko: "정보 압축 1",
              en: "Information Compression 1",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
        ],
      },
      {
        subjectId: 3,
        subjectTitle: {
          ko: "정보 이론을 통한 암호화 기법의 평가와 분석",
          en: "Evaluation and Analysis of Encryption Techniques through Information Theory",
        },
        courses: [
          {
            courseTitle: {
              ko: "암호화 기법 평가 1",
              en: "Evaluation of Encryption Techniques 1",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
          {
            courseTitle: {
              ko: "암호화 기법 평가 2",
              en: "Evaluation of Encryption Techniques 2",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
        ],
      },
    ],
  },
  {
    id: 7,
    title: {
      ko: "증명 가능한 시큐리티 이론",
      en: "Provable Security Theory",
    },
    subject: [
      {
        subjectId: 1,
        subjectTitle: {
          ko: "증명 가능한 시큐리티의 기본 원리와 필요성",
          en: "Basic Principles and Necessities of Provable Security",
        },
        courses: [
          {
            courseTitle: {
              ko: "시큐리티 기본 원리 1",
              en: "Basic Principles of Security 1",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
          {
            courseTitle: {
              ko: "시큐리티 기본 원리 2",
              en: "Basic Principles of Security 2",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
        ],
      },
      {
        subjectId: 2,
        subjectTitle: {
          ko: "증명 가능한 시큐리티 프로토콜과 도구",
          en: "Provable Security Protocols and Tools",
        },
        courses: [
          {
            courseTitle: {
              ko: "시큐리티 프로토콜 1",
              en: "Security Protocols 1",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
          {
            courseTitle: {
              ko: "시큐리티 프로토콜 2",
              en: "Security Protocols 2",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
        ],
      },
    ],
  },
  {
    id: 8,
    title: {
      ko: "암호 및 보안 프로토콜",
      en: "Cryptography and Security Protocols",
    },
    subject: [
      {
        subjectId: 1,
        subjectTitle: {
          ko: "보안 프로토콜의 개요와 종류",
          en: "Overview and Types of Security Protocols",
        },
        courses: [
          {
            courseTitle: {
              ko: "보안 프로토콜 개요 1",
              en: "Overview of Security Protocols 1",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
          {
            courseTitle: {
              ko: "보안 프로토콜 개요 2",
              en: "Overview of Security Protocols 2",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
        ],
      },
      {
        subjectId: 2,
        subjectTitle: {
          ko: "웹 보안과 보안 인증서 관리",
          en: "Web Security and Security Certificate Management",
        },
        courses: [
          {
            courseTitle: {
              ko: "웹 보안 1",
              en: "Web Security 1",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
          {
            courseTitle: {
              ko: "보안 인증서 관리 1",
              en: "Security Certificate Management 1",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
        ],
      },
      {
        subjectId: 3,
        subjectTitle: {
          ko: "클라우드 보안과 데이터 보호 전략",
          en: "Cloud Security and Data Protection Strategies",
        },
        courses: [
          {
            courseTitle: {
              ko: "클라우드 보안 1",
              en: "Cloud Security 1",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
          {
            courseTitle: {
              ko: "데이터 보호 전략 1",
              en: "Data Protection Strategies 1",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
        ],
      },
    ],
  },
  {
    id: 9,
    title: {
      ko: "특정 환경 기반 암호화 기술 개발",
      en: "Developing Specific Environment-Based Encryption Technology",
    },
    subject: [
      {
        subjectId: 1,
        subjectTitle: {
          ko: "클라우드 환경기반 암호 기술시스템",
          en: "Cloud Environment-Based Cryptographic Technology System",
        },
        courses: [
          {
            courseTitle: {
              ko: "클라우드 암호 기술 1",
              en: "Cloud Cryptographic Technology 1",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
          {
            courseTitle: {
              ko: "클라우드 암호 기술 2",
              en: "Cloud Cryptographic Technology 2",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
        ],
      },
      {
        subjectId: 2,
        subjectTitle: {
          ko: "블록체인 환경기반 암호 기술시스템",
          en: "Blockchain Environment-Based Cryptographic Technology System",
        },
        courses: [
          {
            courseTitle: {
              ko: "블록체인 암호 기술 1",
              en: "Blockchain Cryptographic Technology 1",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
          {
            courseTitle: {
              ko: "블록체인 암호 기술 2",
              en: "Blockchain Cryptographic Technology 2",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
        ],
      },
      {
        subjectId: 3,
        subjectTitle: {
          ko: "암호 시스템 설계 및 구현",
          en: "Design and Implementation of Encryption Systems",
        },
        courses: [
          {
            courseTitle: {
              ko: "암호 시스템 설계 1",
              en: "Design of Encryption Systems 1",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
          {
            courseTitle: {
              ko: "암호 시스템 설계 2",
              en: "Design of Encryption Systems 2",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
        ],
      },
      {
        subjectId: 4,
        subjectTitle: {
          ko: "환경별 암호시스템 개발",
          en: "Development of Environment-Specific Encryption System",
        },
        courses: [
          {
            courseTitle: {
              ko: "환경별 암호시스템 1",
              en: "Environment-Specific Encryption System 1",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
          {
            courseTitle: {
              ko: "환경별 암호시스템 2",
              en: "Environment-Specific Encryption System 2",
            },
            duration: "1:10:00",
            link: "https://www.youtube.com/watch?v=tosLBcAX1vk",
          },
        ],
      },
    ],
  },
];

export default CourseList;
